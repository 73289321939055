<template>
  <div class="memberList">

    

    <div class="content" v-infinite-scroll="loadMore" infinite-scroll-distance="0">
      <!-- 顶部 -->
      <Header :borderShow="true" titleName="会员活动" />

      <div class="list_empty" v-if="data.newsList.length == 0&&!data.loading">
        <img src="@/assets/images/list_empty.png" alt="">
        <div class="first_div">暂无活动~</div>
        <div class="last_div">请刷新页面或稍后尝试~</div>
      </div>
      <van-loading color="#888888" v-if="data.loading" vertical style="margin-top: 20vh;">加载中...</van-loading>
      <div class="news" v-if="data.newsList.length > 0&&!data.loading" >
        <div class="news_list">
          <div v-for="(item, index) in data.newsList" :key="index" :class="item.imgUrl ? 'space_between' : ''" @click="toNewDetails(item.id)">
            <img :src="item.imgUrl" alt="" v-if="item.imgUrl">
            <div :class="item.imgUrl ? 'right_box' : ''">
              <div style="font-size: .4267rem;margin-bottom: .16rem;">{{item.title}}</div>
              <div style="color: #ACACAC;" :style="item.imgUrl ? 'font-size: .28rem;' : 'font-size: .32rem;'">
                <span style="margin-right: .2rem;">{{filterTime(item.addTime)}}</span>
                <span>本文被访问次数：{{item.click}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>



    <!-- 底部tab切换 -->
    <!-- <Footer /> -->
  </div>
</template>

<script setup>
import Footer from "../../components/Footer.vue";
import { reactive, toRefs,onMounted,computed } from "vue";
import Header from "../../components/Header.vue";
import { useRoute, useRouter } from 'vue-router'
import Api from "../../utils/api";
import { jsToCallApp } from '../../common/js/callApp1.0.0'
const route = useRoute()
const router = useRouter()

let data = reactive({
  newsList: [],
  loading:true,
  postData: {
    categoryId:2,
    channelId:1,
    pageSize:10,
    pageIndex:1,
  }
});

onMounted(() => {
  getNwesList()
  shareFn()
});

const shareFn = () => {
  const jsonString = {
    shareUrl: 'https://quanzong.cwmia.com/#/member/newsList',//分享链接
    shareCorver: 'https://quanzong.cwmia.com/logo.png',
    shareTitle: '中国职工保障互助会',
    shareDesc: '会员活动',//分享描述
  }
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}

function openAppFunction(type, jsonData) {
  const useTimeOut = setTimeout(() => {
    jsToCallApp(
      {
        type: type,
        ...jsonData,
      },
      (data) => {
        console.log(type, data)
      },
    )
    clearTimeout(useTimeOut);
  }, 300);
}
// 活动专区 - 新闻列表
const getNwesList = ()=>{
    Api.articleViewChannelNew(data.postData).then(res=>{
      data.loading = false
    if(res.status == 200){
        data.newsList = [...data.newsList,...res.data ]
    }
  })
}
const filterTime= computed(() => (timestamp) => {
  timestamp = timestamp.replace(/-/g,'/')
  let time = new Date(timestamp)
  let year = time.getFullYear()
  let month = time.getMonth() + 1
  let date = time.getDate()


  if (month < 10) { month = '0' + month }
  if (date < 10) { date = '0' + date }

  return year + '-' + month + '-' + date 
})

const loadMore = ()=>{
  data.postData.pageIndex++
  console.log(8888)
  getNwesList()
}
const toNewDetails = (id) => {
  router.push({ path: '/news/details', query: { id, }})
};
</script>

<style lang='less' scoped>
/deep/ .van-tabs__wrap {
  border-radius: 10px;
}



.memberList {
  display: flex;
  flex-flow: column;
  height: 100%;
  font-size: 12px;

  .content {
    flex: 1;
    overflow-y: auto;
    .list_empty{
      text-align: center;
      padding-top: 15vh;
      img{
        width: 6.2933rem;
        height: 4.52rem;
      }
      .first_div{
          font-size: .4667rem;
          font-weight: 500;
          margin-top: .6667rem;
        }
        .last_div{
          font-size: .36rem;
          color: #ACACAC;
          margin-top: .2rem;

        }
    }
    .right_img{
      width: .16rem;
    }
    .news{
      background-color: #fff;
      padding: 0 .4rem;
      .news_list {
        >div {
          padding: .4rem 0;
          border-bottom: .0133rem #ececec solid;

          &:last-child {
            border: none;
          }

          img {
            width: 3.0667rem;
            height: 2.3067rem;
            object-fit: cover;
            margin-right: .3733rem;
            border-radius: .1333rem;
          }
        }
      }

      .right_box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 2.3067rem;
        width: calc(100% - 3.5rem);
      }
    }

  }
}
</style>